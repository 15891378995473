.row.header .meta .page {
  padding-top: 7px;
}

body.hamburg .row.header .meta {
  margin-left: 70px;
}

.row.header {
  min-height: 60px;
  background: var(--bg-header-color);
  margin-bottom: 15px;
}
.row.header > div:last-child {
  padding-right: 0;
}
.row.header .meta .page {
  font-size: 17px;
  padding-top: 11px;
}

.row.header .meta div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.row.header .login a {
  padding: 18px;
  display: block;
}
.row.header .user {
  min-width: 130px;
}
.row.header .user > .item {
  width: 65px;
  height: 60px;
  float: right;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
.row.header .user > .item a {
  color: #919191;
  display: block;
}
.row.header .user > .item i {
  font-size: 20px;
  line-height: 55px;
}
.row.header .user > .item img {
  width: 40px;
  height: 40px;
  margin-top: 10px;
  border-radius: 2px;
}
.row.header .user > .item ul.dropdown-menu {
  border-radius: 2px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05);
}
.row.header .user > .item ul.dropdown-menu .dropdown-header {
  text-align: center;
}
.row.header .user > .item ul.dropdown-menu li.link {
  text-align: left;
}
.row.header .user > .item ul.dropdown-menu li.link a {
  padding-left: 7px;
  padding-right: 7px;
}
.row.header .user > .item ul.dropdown-menu:before {
  position: absolute;
  top: -7px;
  right: 23px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
  border-left: 7px solid transparent;
  content: '';
}
.row.header .user > .item ul.dropdown-menu:after {
  position: absolute;
  top: -6px;
  right: 24px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: '';
}

/*angular-loading-bar override*/
#loadingbar-placeholder {
  margin-bottom: 0;
  height: 3px;
}

#loading-bar .bar {
  position: relative;
  height: 3px;
  background: var(--blue-3);
}
/*!angular-loading-bar override*/
